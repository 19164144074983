import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { UntilDestroy } from '@ngneat/until-destroy';
import { bestTimeKey, getDateFromKey, lastPlayedKey, streakKey } from '../constants/local-storage-keys';

@Injectable({
    providedIn: 'root'
})
@UntilDestroy()
export class StatsService {
    private $streak: BehaviorSubject<number>;
    private $bestTime: BehaviorSubject<number | null>;

    constructor() {
        this.$streak = new BehaviorSubject(this.getStreak());
        this.$bestTime = new BehaviorSubject(this.getBestTime());
    }

    public userStreak(): Observable<number> {
        return this.$streak;
    }

    public bestTime(): Observable<number | null> {
        return this.$bestTime;
    }

    public checkStreak(): void {
        const dayDifference = this.getDaysSinceLastPlayed();
        if (dayDifference < 2) {
            return;
        }

        localStorage.setItem(streakKey, '0');
        this.pushLatestStreak();
    }

    public updateStreak(): void {
        let streak = this.getStreak();

        const dayDifference = this.getDaysSinceLastPlayed();

        if (dayDifference < 2) {
            streak += 1;
        } else {
            streak = 1;
        }

        localStorage.setItem(streakKey, streak.toString());

        this.pushLatestStreak();
    }

    public updateBestTime(time: number): void {
        const currentBest = this.getBestTime();
        if (currentBest !== null && currentBest <= time) {
            return;
        }

        localStorage.setItem(bestTimeKey, time.toString());
        this.pushLatestBestTime();
    }

    private getDaysSinceLastPlayed(): number {
        const lastPlayedString = localStorage.getItem(lastPlayedKey);
        if (lastPlayedString === null) {
            return 0;
        }

        const today = new Date();
        const lastPlayed = getDateFromKey(lastPlayedString);

        const msDifference = Math.abs(today.valueOf() - lastPlayed.valueOf());
        return Math.floor(msDifference / (1000 * 60 * 60 * 24));
    }

    private getStreak(): number {
        const streakString = localStorage.getItem(streakKey);

        let streak = 0;
        if (streakString !== null && !isNaN(+streakString)) {
            streak = +streakString;
        }

        return streak;
    }

    private pushLatestStreak(): void {
        this.$streak.next(this.getStreak());
    }

    private getBestTime(): number | null {
        const bestTimeString = localStorage.getItem(bestTimeKey);

        let bestTime = null;
        if (bestTimeString !== null && !isNaN(+bestTimeString)) {
            bestTime = +bestTimeString;
        }

        return bestTime;
    }

    private pushLatestBestTime(): void {
        this.$bestTime.next(this.getBestTime());
    }
}
