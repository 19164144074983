import { Injectable } from '@angular/core';

import { firstLoadKey } from '../constants/local-storage-keys';

@Injectable({
    providedIn: 'root'
})
export class WelcomeService {
    public isFirstLoad(): boolean {
        return localStorage.getItem(firstLoadKey) === null;
    }

    public firstLoadComplete(): void {
        localStorage.setItem(firstLoadKey, 'true');
    }
}
