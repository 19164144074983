import { Injectable } from '@angular/core';

import { distributions } from 'src/assets/letter-distributions';

import { DistributionGroup } from '../models/distribution-group';
import { GameSettings } from '../models/game-settings';

@Injectable({
  providedIn: 'root'
})
export class LetterService {
    private allLetters: DistributionGroup;
    private vowels: DistributionGroup;
    private allConsonants: DistributionGroup;
    private easyConsonants: DistributionGroup;

    constructor() {
        this.allLetters = new DistributionGroup(distributions);
        this.vowels = new DistributionGroup(distributions.filter(d => !!d.isVowel));
        this.allConsonants = new DistributionGroup(distributions.filter(d => !d.isVowel));
        this.easyConsonants = new DistributionGroup(distributions.filter(d => !d.isVowel && d.frequency >= 6));
    }

    public getLetters(settings: GameSettings): string[] {
        const result: string[] = [];

        result.push(...this.vowels.getLetters(settings.vowelCount));
        result.push(...this.easyConsonants.getLetters(settings.easyConsonantCount));
        result.push(...this.allConsonants.getLetters(settings.otherConsonantCount));
        result.push(...this.allLetters.getLetters(settings.otherLetterCount));

        return result;
    }
}
