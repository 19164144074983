import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { GameResult } from 'src/app/models/game-result';
import { easyGameSettings } from 'src/app/models/game-settings';
import { DisplayUser } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { DailyGameService } from 'src/app/services/daily-game.service';
import { LetterService } from 'src/app/services/letter.service';
import { StatsService } from 'src/app/services/stats.service';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@Component({
  selector: 'app-standard-game',
  templateUrl: './standard-game.component.html'
})
@UntilDestroy()
export class StandardGameComponent implements OnInit {
    public letters: string[] = [];
    public loading = true;

    public user: DisplayUser | null = null;
    public gameComplete = false;
    public addingGame = false;

    constructor(
        private dailyService: DailyGameService,
        private authService: AuthService,
        private statsService: StatsService,
        private letterService: LetterService,
        private router: Router,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit(): void {
        // if (this.dailyService.isFirstDailyVisit()) {
        //     this.router.navigate(['daily']);
        //     return;
        // }

        this.subscribeToLoginState();
        this.resetLetters();
    }

    public onGameComplete(result: GameResult): void {
        this.gameComplete = true;
        this.statsService.updateBestTime(result.time);
    }

    public resetLetters(): void {
        this.gameComplete = false;
        this.letters = this.letterService.getLetters(easyGameSettings);

        setTimeout(() => this.loading = false, 1000);
    }

    public shareGame(result: GameResult): void {
        navigator.clipboard.writeText(result.customGameLink);

        const config: MatSnackBarConfig = { duration: 3000 };
        this.snackBar.open('Link copied to clipboard', undefined, config);
    }

    public async addDailyGame(): Promise<void> {
        if (this.addingGame) {
            return;
        }
        this.addingGame = true;

        let message = 'Error adding game to database';

        if (this.gameComplete && (this.letters || []).length === 10) {
            try {
                const added = await this.dailyService.addDailyGame(this.letters);
                message = added ? 'Game added to database' : 'Game already exists in database';
            } catch (error: any) {
                message = error.toString();
            }
        }

        const config: MatSnackBarConfig = { duration: 3000 };

        this.snackBar.open(message, undefined, config);
        this.addingGame = false;
    }

    private subscribeToLoginState(): void {
        this.authService.$user
            .pipe(untilDestroyed(this))
            .subscribe(user => this.user = user);
    }
}
