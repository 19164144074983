import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';

import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { usersPath } from '../constants/database-paths';
import { DisplayUser, StoredUser } from '../models/user';

import firebase from 'firebase/app';

@Injectable({
    providedIn: 'root'
})
@UntilDestroy()
export class AuthService {
    public $user: BehaviorSubject<DisplayUser | null>;

    constructor(private fireAuth: AngularFireAuth, private db: AngularFirestore) {
        this.$user = new BehaviorSubject<DisplayUser | null>(null);

        this.subscribeToLoginState();
    }

    public googleSignIn(): Promise<firebase.auth.UserCredential> {
        return this.fireAuth
            .signInWithPopup(new firebase.auth.GoogleAuthProvider());
    }

    public anonymousSignIn(): Promise<any> {
        return this.fireAuth.signInAnonymously();
    }

    public signOut(): Promise<void> {
        return this.fireAuth.signOut();
    }

    private subscribeToLoginState(): void {
        this.fireAuth.authState
            .pipe(untilDestroyed(this))
            .subscribe(user => this.setUserData(user));
    }

    private async setUserData(firebaseUser: firebase.User | null): Promise<void> {
        let user: DisplayUser | null = null;
        if (firebaseUser != null) {
            const isAnonymous = firebaseUser.isAnonymous;
            const admin = !isAnonymous && await this.userHasRole(firebaseUser.uid, 'admin');
            user = { displayName : firebaseUser.displayName || firebaseUser.uid, isAdmin: admin, isAnonymous };
        }

        this.$user.next(user);
    }

    private async userHasRole(uid: string, role: string): Promise<boolean> {
        const user = await this.db.doc<StoredUser>(`${usersPath}/${uid}`)
            .valueChanges()
            .pipe(
                untilDestroyed(this),
                take(1)
            )
            .toPromise();

        return (user?.roles || []).includes(role);
    }
}
