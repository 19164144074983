import { gridToEmojis } from '../functions/grid-to-emojis';
import { timeToString } from '../functions/time-to-string';

export class GameResult {
    constructor(public time: number, public letters: string[], public grid: string[][]) {}

    public get timeString(): string {
        return timeToString(this.time);
    }

    public get letterString(): string {
        return this.letters.join('').toLowerCase();
    }

    public get gridString(): string {
        return this.grid.reduce((result, space) => {
            return result + (space.length > 0 ? space[0] : '-');
        }, '');
    }

    public get emojiString(): string {
        return gridToEmojis(this.grid);
    }

    public get customGameLink(): string {
        return `I completed this game of Griddle in ${this.timeString}\r\n` +
            `Try to beat my time here: griddle.jevans.uk/custom/${this.letterString}\r\n\r\n` +
            this.emojiString;
    }

    public get dailyGameLink(): string {
        return `I completed the daily Griddle in ${this.timeString}\r\n` +
            `Try to beat my time here: griddle.jevans.uk/daily\r\n\r\n` +
            this.emojiString;
    }
}
