import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

import { GameResult } from 'src/app/models/game-result';
import { DisplayUser } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { DailyGameService } from 'src/app/services/daily-game.service';
import { StatsService } from 'src/app/services/stats.service';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@Component({
  selector: 'app-custom-game',
  templateUrl: './custom-game.component.html',
  styleUrls: ['./custom-game.component.scss']
})
@UntilDestroy()
export class CustomGameComponent implements OnInit {
    public letters: string[] = [];
    public loading = true;

    public user: DisplayUser | null = null;
    public gameComplete = false;
    public addingGame = false;

    constructor(
        private dailyService: DailyGameService,
        private statsService: StatsService,
        private authService: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit(): void {
        if (this.dailyService.isFirstDailyVisit()) {
            this.router.navigate(['daily']);
            return;
        }

        this.subscribeToLoginState();
        this.getLettersFromRoute();
    }

    public getLettersFromRoute(): void {
        this.route.paramMap
            .pipe(untilDestroyed(this))
            .subscribe(params => {
                this.loading = true;

                this.letters = [];
                const routeLetters = params.get('letters');
                if (routeLetters !== null && !!routeLetters.match('[a-zA-Z]{10}')) {
                    this.letters = routeLetters.toUpperCase().split('');
                }

                this.loading = false;
            });
    }

    public onGameComplete(result: GameResult): void {
        this.gameComplete = true;
        this.statsService.updateBestTime(result.time);
    }

    public newGame(): void {
        this.gameComplete = false;
        this.router.navigate(['/']);
    }

    public shareGame(result: GameResult): void {
        navigator.clipboard.writeText(result.customGameLink);

        const config: MatSnackBarConfig = { duration: 3000 };
        this.snackBar.open('Link copied to clipboard', undefined, config);
    }

    public async addDailyGame(): Promise<void> {
        if (this.addingGame) {
            return;
        }
        this.addingGame = true;

        let message = 'Error adding game to database';
        let isError = true;

        if (this.gameComplete && (this.letters || []).length === 10) {
            try {
                const added = await this.dailyService.addDailyGame(this.letters);
                message = added ? 'Game added to database' : 'Game already exists in database';
                isError = !added;
            } catch (error: any) {
                message = error.toString();
            }
        }

        const config: MatSnackBarConfig = {
            duration: 3000,
            panelClass: isError ? 'error' : undefined
        };

        this.snackBar.open(message, undefined, config);
        this.addingGame = false;
    }

    private subscribeToLoginState(): void {
        this.authService.$user
            .pipe(untilDestroyed(this))
            .subscribe(user => this.user = user);
    }
}
