import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent {
    public showExamples = false;

    constructor(private dialogRef: MatDialogRef<WelcomeComponent>) {}

    done(): void {
        this.dialogRef.close();
    }
}
