<ng-container *ngIf="!loading; else loadingContainer">
    <app-game [letters]="letters" (gameCompleted)="onGameComplete($event)" (shareGameClicked)="shareGame($event)" (gameReset)="resetLetters()"></app-game>

    <div *ngIf="gameComplete && !!user?.isAdmin" class="ta-c my-2">
        <button mat-flat-button color="primary" (click)="addDailyGame()" [disabled]="addingGame">Submit as daily game</button>
    </div>
</ng-container>

<ng-template #loadingContainer>
    <app-loading></app-loading>
</ng-template>
