import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';

import { AppStateModel } from '../models/app-state';
import { SetHelpModalOpen, SetMenuOpen, SetWindowFocus } from './app.actions';

@State<AppStateModel>({
    name: 'scoreboard',
    defaults: {
        menuIsOpen: false,
        modalIsOpen: false,
        windowHasFocus: true
    }
})
@Injectable()
export class AppState {
    @Selector()
    static pause(state: AppStateModel): boolean {
        return state.menuIsOpen || state.modalIsOpen || !state.windowHasFocus;
    }

    @Action(SetMenuOpen)
    setMenuOpen(ctx: StateContext<AppStateModel>, { menuIsOpen }: SetMenuOpen): void {
        ctx.patchState({
            menuIsOpen
        });
    }

    @Action(SetHelpModalOpen)
    setModalOpen(ctx: StateContext<AppStateModel>, { modalIsOpen }: SetHelpModalOpen): void {
        ctx.patchState({
            modalIsOpen
        });
    }

    @Action(SetWindowFocus)
    setWindowFocus(ctx: StateContext<AppStateModel>, { windowHasFocus }: SetWindowFocus): void {
        ctx.patchState({
            windowHasFocus
        });
    }
}
