import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FirebaseModule } from './firebase/firebase.module';
import { LoadingComponent } from './loading/loading.component';
import { MaterialModule } from './material/material.module';

@NgModule({
    declarations: [
        LoadingComponent
    ],
    imports: [
        CommonModule,
        FirebaseModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [
        LoadingComponent,

        FirebaseModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule
    ]
})
export class SharedModule { }
