import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CustomGameComponent } from './mode/custom-game/custom-game.component';
import { DailyGameComponent } from './mode/daily-game/daily-game.component';
import { StandardGameComponent } from './mode/standard-game/standard-game.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { SignInComponent } from './sign-in/sign-in.component';

const routes: Routes = [
    { path: '', component: StandardGameComponent },
    { path: 'daily', component: DailyGameComponent },
    { path: 'custom/:letters', component: CustomGameComponent },
    { path: 'sign-in', component: SignInComponent },
    { path: 'privacy', component: PrivacyComponent },
    { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
    imports: [ RouterModule.forRoot(routes) ],
    exports: [ RouterModule ]
})
export class AppRoutingModule {}
