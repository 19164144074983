import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';

import { Store } from '@ngxs/store';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WelcomeComponent } from './dialogs/welcome/welcome.component';
import { AuthService } from './services/auth.service';
import { StatsService } from './services/stats.service';
import { WelcomeService } from './services/welcome.service';
import { SetHelpModalOpen, SetMenuOpen } from './store/app.actions';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
@UntilDestroy()
export class AppComponent implements OnInit {
    @ViewChild(MatSidenav) drawer?: MatSidenav;

    title = 'griddle';

    public loading = true;
    public isLoggedIn = false;
    public loginFail = false;

    constructor(
        private welcomeService: WelcomeService,
        private store: Store,
        private dialog: MatDialog,
        private userDataService: StatsService,
        private authService: AuthService
    ) {}

    ngOnInit(): void {
        this.subscribeToLoginState();
        this.updateStreak();

        if (this.welcomeService.isFirstLoad()) {
            this.showWelcomeDialog();
        }
    }

    public onMenuOpenChanged(open: boolean): void {
        this.store.dispatch(new SetMenuOpen(open));
    }

    public showWelcomeDialog(): void {
        this.store.dispatch(new SetHelpModalOpen(true));

        this.dialog
            .open(WelcomeComponent)
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.store.dispatch(new SetHelpModalOpen(false));
                this.welcomeService.firstLoadComplete();
            });
    }

    private updateStreak(): void {
        this.userDataService.checkStreak();
    }

    private subscribeToLoginState(): void {
        this.authService.$user
            .pipe(untilDestroyed(this))
            .subscribe(user => {
                this.isLoggedIn = !!user;

                if (!this.isLoggedIn) {
                    this.loading = true;
                    setTimeout(() => this.anonymousSignIn(), 2000);
                } else {
                    this.loading = false;
                }
            });
    }

    private anonymousSignIn(): void {
        if (this.isLoggedIn) {
            return;
        }

        this.authService.anonymousSignIn()
            .then(() => this.loading = false)
            .catch(() => {
                this.loading = false;
                this.loginFail = true;
            });
    }
}
