import { LetterDistribution } from './letter-distribution';

export class DistributionGroup {
    private total: number;

    constructor(private distributions: LetterDistribution[]) {
        this.total = this.distributions.reduce((total, d) => total += d.frequency, 0);
    }

    public getLetters(count: number): string[] {
        const result: string[] = [];

        while (result.length < count) {
            const letter = this.getLetter();
            if (letter !== null) {
                result.push(letter);
            }
        }

        return result;
    }

    private getLetter(): string | null {
        const target = Math.floor(Math.random() * this.total);
        let currentCount = 0;
        let result: string | null = null;

        this.distributions.every(d => {
            currentCount += d.frequency;
            if (currentCount >= target) {
                result = d.letter;
                return false;
            }
            return true;
        });

        return result;
    }
}
