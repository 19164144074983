export class GameSettings {
    constructor(
        public totalLetterCount: number,
        public vowelCount: number,
        public easyConsonantCount: number,
        public otherConsonantCount: number
    ) {}

    public get otherLetterCount(): number {
        return Math.max(0, this.totalLetterCount - (this.vowelCount + this.easyConsonantCount + this.otherConsonantCount));
    }
}

export const easyGameSettings = new GameSettings(10, 2, 4, 2);
