<mat-sidenav-container>
    <mat-sidenav #drawer mode="over" (openedChange)="onMenuOpenChanged($event)">
        <ng-container *ngTemplateOutlet="menuItems"></ng-container>

        <div class="footer">
            <div class="footer-content py-1">
                <ng-container *ngTemplateOutlet="footerContent"></ng-container>
            </div>
        </div>
    </mat-sidenav>

    <mat-toolbar color="primary">
        <div class="toolbar-content">
            <button mat-button class="menu-button" (click)="drawer.toggle()">
                <mat-icon>menu</mat-icon>
            </button>

            <span class="mobile-spacer"></span>

            <ng-container *ngTemplateOutlet="menuItems"></ng-container>

            <span class="flex-spacer"></span>

            <button mat-button class="help-button" (click)="showWelcomeDialog()">
                <mat-icon>help_outline</mat-icon>
            </button>
        </div>
    </mat-toolbar>

    <div class="content-container">
        <ng-container *ngIf="loading; else appContent">
            <app-loading message="Connecting to Griddle..."></app-loading>
        </ng-container>

        <ng-template #appContent>
            <router-outlet></router-outlet>
        </ng-template>
    </div>

    <div class="footer wide">
        <div class="footer-content">
            <ng-container *ngTemplateOutlet="footerContent"></ng-container>
        </div>
    </div>
</mat-sidenav-container>

<ng-template #menuItems>
    <a mat-button class="home-button" routerLink="/">Griddle</a>

    <a mat-button class="game-button" routerLink="/daily" routerLinkActive="active-link" (click)="drawer.close()">
        <mat-icon>today</mat-icon>
        Daily Game
    </a>

    <a mat-button class="game-button" routerLink="/" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true }" (click)="drawer.close()">
        <mat-icon>sports_esports</mat-icon>
        Free Play
    </a>

    <a mat-button class="game-button" routerLink="/sign-in" routerLinkActive="active-link" (click)="drawer.close()">
        <mat-icon>person</mat-icon>
        Account
    </a>

</ng-template>

<ng-template #footerContent>
    <a routerLink="/privacy" routerLinkActive="active-link" (click)="drawer.close()">
        Privacy Policy
    </a>
</ng-template>
