<mat-toolbar color="primary">
    <h1 class="ta-c">Welcome to Griddle!</h1>
</mat-toolbar>

<div class="welcome-dialog-content">
    <div class="pt-2 px-2" [class.invisible]="showExamples">
        <p>Griddle is a word game where you have to create a valid grid with the given tiles, as fast as you can.</p>

        <p>Drag the tiles onto the grid to place them, and then hit the validate button:</p>

        <div class="ta-c">
            <button class="mb-2" mat-flat-button color="primary" matTooltip="Validate">
                <mat-icon>done</mat-icon>
            </button>
        </div>

        <p>You can swap tiles by dropping one on top of another.</p>

        <p>You can use the recall button to remove all tiles from the grid:</p>

        <div class="ta-c">
            <button class="mb-2" mat-flat-button matTooltip="Return letters">
                <mat-icon>save_alt</mat-icon>
            </button>
        </div>

        <p>You must use all the tiles</p>

        <p>All words must be connected</p>

        <p>If you misspell a word or use one that isn't valid, you will have <span class="error">10 seconds</span> added to your time</p>

        <p>Good luck, and don't forget to share your time when you're done!</p>
    </div>

    <div class="content-block">
        <img src="assets/images/example-game.gif" [class.invisible]="!showExamples" alt="Gif showing the playthrough of an example game" />
    </div>

    <div class="button-container">
        <button class="mx-1" mat-flat-button (click)="showExamples = !showExamples">
            Show {{ showExamples ? 'rules' : 'example' }}
        </button>

        <button class="mx-1" mat-flat-button color="primary" (click)="done()">
            I'm ready!
        </button>
    </div>
</div>