import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    dailyGameGridKey, dailyGameLettersKey, dailyGameTimeKey, getDateFromKey, getKeyFromDate, lastPlayedKey, lastVisitedKey
} from '../constants/local-storage-keys';
import { addDaysToDate } from '../functions/add-days';
import { letterArraysAreEqual } from '../functions/letter-arrays-equal';
import { AddedGameMeta } from '../models/added-game-meta';
import { DailyGame } from '../models/daily-game';
import { GameResult } from '../models/game-result';
import { addedGamesMetaPath, dailyGamesPath } from '../constants/database-paths';

@Injectable({
    providedIn: 'root'
})
@UntilDestroy()
export class DailyGameService {
    constructor(private db: AngularFirestore) {}

    public isFirstDailyVisit(): boolean {
        const todayKey = getKeyFromDate(new Date());
        const lastVisited = localStorage.getItem(lastVisitedKey);

        if (todayKey !== lastVisited) {
            localStorage.setItem(lastVisitedKey, todayKey);
            return true;
        }

        return false;
    }

    public getDailyLetters(): Observable<DailyGame | undefined> {
        const todayKey = getKeyFromDate(new Date());
        return this.db.doc<DailyGame>(`${dailyGamesPath}/${todayKey}`)
            .valueChanges()
            .pipe(untilDestroyed(this));
    }

    public getDailyGameResult(): GameResult | null {
        const todayKey = getKeyFromDate(new Date());
        const lastPlayedDate = localStorage.getItem(lastPlayedKey);

        if (todayKey !== lastPlayedDate) {
            localStorage.removeItem(dailyGameTimeKey);
            localStorage.removeItem(dailyGameLettersKey);
            localStorage.removeItem(dailyGameGridKey);

            return null;
        }

        return this.getGameResultFromStorage();
    }

    public saveDailyGameResult(result: GameResult): void {
        const todayKey = getKeyFromDate(new Date());
        localStorage.setItem(lastPlayedKey, todayKey);

        localStorage.setItem(dailyGameTimeKey, result.time.toString());
        localStorage.setItem(dailyGameLettersKey, result.letterString);
        localStorage.setItem(dailyGameGridKey, result.gridString);
    }

    public async addDailyGame(letters: string[]): Promise<boolean> {
        const gameCollection = this.db.collection<DailyGame>(
            dailyGamesPath,
            ref => ref.orderBy('dateCreated', 'desc')
        );

        const games = await gameCollection
            .valueChanges()
            .pipe(
                untilDestroyed(this),
                take(1)
            )
            .toPromise();

        if (games.some(game => letterArraysAreEqual(game, letters))) {
            return false;
        }

        const nextKey = await this.getNextAvailableKey();
        if (!nextKey) {
            return false;
        }

        const newDailyGame: DailyGame = { letters, dateCreated: new Date() };
        await gameCollection.doc<DailyGame>(nextKey).set(newDailyGame);

        const gameCount = await this.getDbGameCount();
        await this.db.doc<AddedGameMeta>(addedGamesMetaPath).set({ lastAddedGame: nextKey, count: gameCount + 1 });

        console.log('Added new game: ' + nextKey);

        return true;
    }

    private getGameResultFromStorage(): GameResult | null {
        const timeString = localStorage.getItem(dailyGameTimeKey);
        const letterString = localStorage.getItem(dailyGameLettersKey);
        const gridString = localStorage.getItem(dailyGameGridKey);

        if (timeString === null || letterString === null || gridString === null) {
            return null;
        }

        const time = +timeString;
        if (isNaN(time)) {
            return null;
        }

        if (!letterString.match('[A-Za-z]{10}')) {
            return null;
        }
        const letters = letterString.toUpperCase().split('');

        const grid = gridString.split('').map(space => space === '-' ? [] : [space]);
        if (grid.filter(space => space.length > 0).length < 10) {
            return null;
        }

        return new GameResult(time, letters, grid);
    }

    private async getNextAvailableKey(): Promise<string> {
        const dbMeta = await this.getDbMeta();

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        let date = dbMeta != null ? getDateFromKey(dbMeta.lastAddedGame) : today;
        date.setHours(0, 0, 0, 0);

        if (date < today) {
            date = today;
        } else {
            date = addDaysToDate(date, 1);
        }

        return getKeyFromDate(date);
    }

    private async getDbGameCount(): Promise<number> {
        const dbMeta = await this.getDbMeta();
        return dbMeta == null ? 0 : dbMeta.count;
    }

    private async getDbMeta(): Promise<AddedGameMeta | undefined> {
        return this.db.doc<AddedGameMeta>(addedGamesMetaPath)
        .valueChanges()
        .pipe(
            untilDestroyed(this),
            take(1)
        )
        .toPromise();
    }
}
