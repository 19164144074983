<ng-container *ngIf="!loading; else loadingContainer">
    <ng-container *ngIf="letters.length === 10; else errorContainer">
        <app-game [letters]="letters" (gameCompleted)="onGameComplete($event)" (shareGameClicked)="shareGame($event)" (gameReset)="newGame()"></app-game>
    </ng-container>

    <div *ngIf="gameComplete && !!user?.isAdmin" class="ta-c my-2">
        <button mat-flat-button color="primary" (click)="addDailyGame()" [disabled]="addingGame">Submit as daily game</button>
    </div>
</ng-container>

<ng-template #loadingContainer>
    <app-loading></app-loading>
</ng-template>

<ng-template #errorContainer>
    <div class="ta-c">
        <h2 class="mat-display-1 mt-4 mb-2">Unable to load custom game</h2>
        <p>Check the link you were given - it should be in the form <a routerLink="/custom/abcdefghij">griddle.jevans.uk/custom/abcdefghij</a></p>
        <a mat-flat-button color="primary" routerLink="/">New Game</a>
    </div>
</ng-template>
