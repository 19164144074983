import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxsModule } from '@ngxs/store';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WelcomeComponent } from './dialogs/welcome/welcome.component';
import { GameComponent } from './game/game.component';
import { CustomGameComponent } from './mode/custom-game/custom-game.component';
import { DailyGameComponent } from './mode/daily-game/daily-game.component';
import { StandardGameComponent } from './mode/standard-game/standard-game.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { SharedModule } from './shared/shared.module';
import { SignInComponent } from './sign-in/sign-in.component';
import { AppState } from './store/app.state';

@NgModule({
  declarations: [
    AppComponent,
    GameComponent,
    StandardGameComponent,
    CustomGameComponent,
    DailyGameComponent,
    WelcomeComponent,
    SignInComponent,
    PrivacyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,

    NgxsModule.forRoot([AppState]),

    SharedModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
