export const lastPlayedKey = 'last-played';
export const lastVisitedKey = 'last-visited';

export const dailyGameTimeKey = 'daily-game/time';
export const dailyGameLettersKey = 'daily-game/letters';
export const dailyGameGridKey = 'daily-game/grid';

export const userKey = 'user';
export const firstLoadKey = 'user/has-visited';
export const bestTimeKey = 'user/best-time';
export const streakKey = 'user/streak';

export function getKeyFromDate(date: Date): string {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
}

export function getDateFromKey(dateKey: string): Date {
    const [ year, month, day ] = dateKey.split('-').map(key => +key);
    return new Date(year, month - 1, day);
}
