export class Coordinates {
    constructor(public x: number, public y: number) {}

    add(other: Coordinates): Coordinates {
        return new Coordinates(this.x + other.x, this.y + other.y);
    }

    equals(other: Coordinates): boolean {
        return this.x === other.x && this.y === other.y;
    }

    duplicate(): Coordinates {
        return new Coordinates(this.x, this.y);
    }
}
