import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { GameComponent } from 'src/app/game/game.component';
import { GameResult } from 'src/app/models/game-result';
import { DailyGameService } from 'src/app/services/daily-game.service';
import { StatsService } from 'src/app/services/stats.service';

@Component({
  selector: 'app-daily-game',
  templateUrl: './daily-game.component.html'
})
export class DailyGameComponent implements OnInit {
    @ViewChild(GameComponent, { static: false }) game?: GameComponent;

    public letters: string[] = [];
    public loading = true;

    constructor(
        private dailyService: DailyGameService,
        private statsService: StatsService,
        private router: Router,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit(): void {
        this.startSubscription();
    }

    public startSubscription(): void {
        const previousResult = this.dailyService.getDailyGameResult();
        if (previousResult !== null) {
            this.loading = false;
            this.letters = previousResult.letters;

            setTimeout(() => this.game?.setCompletedGrid(previousResult), 100);
            return;
        }

        this.dailyService.getDailyLetters()
            .subscribe(dailyGame => {
                this.loading = true;

                this.letters = [];
                if (!!dailyGame && (dailyGame.letters || []).length === 10) {
                    this.letters = dailyGame.letters;
                }

                this.loading = false;
            });
    }

    public onGameComplete(result: GameResult): void {
        this.statsService.updateStreak();
        this.statsService.updateBestTime(result.time);

        this.dailyService.saveDailyGameResult(result);
    }

    public newGame(): void {
        this.router.navigate(['/']);
    }


    public shareGame(result: GameResult): void {
        navigator.clipboard.writeText(result.dailyGameLink);

        const config: MatSnackBarConfig = { duration: 3000 };
        this.snackBar.open('Link copied to clipboard', undefined, config);
    }
}
