import { DailyGame } from '../models/daily-game';

export function letterArraysAreEqual(dailyGame: DailyGame, proposedLetters: string[]): boolean {
    const existingLetters = [...dailyGame.letters];
    let foundLetterIndex = -1;
    let result = true;

    proposedLetters.every(letter => {
        foundLetterIndex = existingLetters.indexOf(letter);
        if (foundLetterIndex > -1) {
            existingLetters.splice(foundLetterIndex, 1);
            return true;
        } else {
            result = false;
            return false;
        }
    });

    return result && !existingLetters.length;
}
