<div class="clock-container">
    <ng-container *ngIf="clock < 36000; else outOfTime">
        <div class="time">{{ timeToString(clock) }}</div>
        <div *ngIf="gameStarted && !gameComplete" class="time-penalty ml-1" [class.visible]="invalidGrid">+10</div>
    </ng-container>

    <ng-template #outOfTime>
        <div>Out of time</div>
    </ng-template>

    <span class="flex-spacer"></span>

    <ng-container *ngIf="gameStarted; else startGameContainer">
        <ng-container *ngIf="!paused || gameComplete">
            <ng-container *ngIf="!gameComplete; else newGameContainer">
                <button *ngIf="bank1.length + bank2.length < 10" mat-flat-button (click)="returnLetters()" matTooltip="Return letters">
                    <mat-icon>save_alt</mat-icon>
                </button>

                <button class="ml-1" mat-flat-button color="primary" (click)="validate()" matTooltip="Validate">
                    <mat-icon>done</mat-icon>
                </button>
            </ng-container>

            <ng-template #newGameContainer>
                <button class="mx-1" mat-flat-button (click)="resetGame()">New Game</button>

                <button class="ml-1" color="primary" mat-flat-button (click)="share()">
                    Share
                    <mat-icon class="ml-1">share</mat-icon>
                </button>
            </ng-template>
        </ng-container>
    </ng-container>

    <ng-template #startGameContainer>
        <button class="ml-1" mat-flat-button color="primary" (click)="startClock()">Start!</button>
    </ng-template>
</div>

<div *ngIf="!gameStarted || !paused || gameComplete; else gridHidden" class="game-container" cdkDropListGroup>
    <div class="grid">
        <div
            *ngFor="let gridSpace of gridSpaces; let i = index"
            class="grid-drop-box"
            [class.valid]="validSpaces[i] === SpaceValidity.Valid"
            [class.invalid]="validSpaces[i] === SpaceValidity.Invalid"
        >
            <div
                cdkDropList
                [cdkDropListData]="gridSpace"
                [cdkDropListDisabled]="gameComplete"
                (cdkDropListDropped)="drop($event)"
            >
                <div
                    class="example-box"
                    *ngFor="let letter of gridSpace"
                    cdkDrag
                    [cdkDragData]="letter"
                >
                    {{letter}}
                </div>
            </div>
            <div class="icon-container"><mat-icon>close</mat-icon></div>
        </div>
    </div>

    <div *ngIf="gameStarted" class="bank-container">
        <div
            class="bank-list"
            cdkDropList
            [cdkDropListData]="bank1"
            cdkDropListOrientation="horizontal"
            [cdkDropListDisabled]="gameComplete"
            (cdkDropListDropped)="drop($event, true)"
        >
            <div
                class="example-box"
                *ngFor="let letter of bank1"
                cdkDrag
                [cdkDragData]="letter"
            >{{letter}}</div>
        </div>

        <div
            class="bank-list"
            cdkDropList
            [cdkDropListData]="bank2"
            cdkDropListOrientation="horizontal"
            [cdkDropListDisabled]="gameComplete"
            (cdkDropListDropped)="drop($event, true)"
        >
            <div
                class="example-box"
                *ngFor="let letter of bank2"
                cdkDrag
                [cdkDragData]="letter"
            >{{letter}}</div>
        </div>
    </div>
</div>

<ng-template #gridHidden>
    <div class="mt-2 mx-2 ta-c">
        <mat-icon class="mb-1">visibility_off</mat-icon>
        <p>Whilst you're away, the grid is hidden and the timer is paused</p>
        <p>Click anywhere to continue</p>
    </div>
</ng-template>