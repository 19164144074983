export class SetMenuOpen {
    static readonly type = '[App] Set Menu Open';
    constructor(public menuIsOpen: boolean) {}
}

export class SetHelpModalOpen {
    static readonly type = '[App] Set Help Modal Open';
    constructor(public modalIsOpen: boolean) {}
}

export class SetWindowFocus {
    static readonly type = '[App] Set Window Focus';
    constructor(public windowHasFocus: boolean) {}
}
