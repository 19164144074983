import { GridSpace } from './grid-space';

export class GridResult {
    validWords: GridSpace[][] = [];
    invalidWords: GridSpace[][] = [];
    separatedWords: GridSpace[][] = [];

    public get isValid(): boolean {
        return !this.invalidWords.length && !this.separatedWords.length;
    }
}
