import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { timeToString } from '../functions/time-to-string';
import { DisplayUser } from '../models/user';
import { AuthService } from '../services/auth.service';
import { StatsService } from '../services/stats.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
@UntilDestroy()
export class SignInComponent implements OnInit {
    public loading = true;

    public user: DisplayUser | null = null;
    public bestTime: string | null = null;
    public streak: number | null = null;

    constructor(
        private authService: AuthService,
        private userDataService: StatsService,
        private router: Router,
        private snackbar: MatSnackBar
    ) { }

    ngOnInit(): void {
        this.getUserStatistics();

        this.authService.$user
            .pipe(untilDestroyed(this))
            .subscribe(user => this.user = user);

        setTimeout(() => {
            this.loading = false;
        }, 1000);
    }

    public signIn(): void {
        this.loading = true;

        this.authService.googleSignIn()
            .then(() => {
                this.router.navigate(['/']);
            })
            .catch(err => {
                this.loading = false;
                this.snackbar.open(err, 'Okay');
            });
    }

    public signOut(): void {
        this.authService.signOut()
            .then()
            .catch(err => {
                this.snackbar.open(err, 'Okay');
            });
    }

    private getUserStatistics(): void {
        this.userDataService.userStreak()
            .pipe(untilDestroyed(this))
            .subscribe(streak => this.streak = streak);

        this.userDataService.bestTime()
            .pipe(untilDestroyed(this))
            .subscribe(best => this.bestTime = best !== null ? timeToString(best) : best);
    }
}
