<ng-container *ngIf="!loading; else loadingContainer">
    <ng-container *ngIf="letters.length === 10; else errorContainer">
        <app-game [letters]="letters" (gameCompleted)="onGameComplete($event)" (shareGameClicked)="shareGame($event)" (gameReset)="newGame()"></app-game>
    </ng-container>
</ng-container>

<ng-template #loadingContainer>
    <app-loading></app-loading>
</ng-template>

<ng-template #errorContainer>
    <div class="ta-c">
        <h2 class="mat-display-1 mt-4 mb-2">Griddle daily is no longer supported</h2>
        <p>Due to the upkeep required to create daily games, Griddle daily is no longer being maintained.</p>
        <p>However, you can still play in free play mode!</p>
        <a mat-flat-button
           color="primary"
           routerLink="/">New Game</a>
    </div>
</ng-template>