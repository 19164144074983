import { Component } from '@angular/core';

@Component({
    selector: 'app-privacy',
    templateUrl: './privacy.component.html',
    styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent {
    constructor() { }

    public scrollToElement($element: Element): void {
        $element.scrollIntoView({ behavior: 'smooth' });
    }
}
