<div class="ta-c mt-2">
    <ng-container *ngIf="!loading; else loadingDisplay">
        <!-- ** STATS ** -->
        <h2>Daily Streak: {{ streak }}</h2>
        <h2 *ngIf="bestTime">Best Time: {{ bestTime }}</h2>

        <hr class="mb-2">

        <!-- ** SIGN IN ** -->
        <ng-container *ngIf="!user || user.isAnonymous">
            <h2>Signing in is neither <b>necessary nor recommended</b> for non-admin users</h2>
            <h2>If you aren't sure whether you're an admin, <b>you are not an admin</b></h2>

            <button type="button" mat-flat-button class="mt-2 mx-a" (click)="signIn()">
                <i class="fab fa-google-plus-g"></i>
                Log in with Google
            </button>
        </ng-container>

        <!-- ** ACCOUNT AND SIGN OUT ** -->
        <ng-container *ngIf="user && !user.isAnonymous">
            <h1>Logged in as {{user.displayName}}</h1>
            <h3 *ngIf="user.isAdmin">Current user is an admin user</h3>

            <button type="button" mat-flat-button class="mt-2 mx-a" (click)="signOut()">
                Log out
            </button>
        </ng-container>
    </ng-container>
</div>

<ng-template #loadingDisplay>
    <app-loading></app-loading>
</ng-template>
