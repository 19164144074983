import { LetterDistribution } from 'src/app/models/letter-distribution';

export const distributions: LetterDistribution[] = [
    {
        letter: 'A',
        frequency: 13,
        isVowel: true
    },
    {
        letter: 'B',
        frequency: 3
    },
    {
        letter: 'C',
        frequency: 3
    },
    {
        letter: 'D',
        frequency: 6
    },
    {
        letter: 'E',
        frequency: 18,
        isVowel: true
    },
    {
        letter: 'F',
        frequency: 3
    },
    {
        letter: 'G',
        frequency: 4
    },
    {
        letter: 'H',
        frequency: 3
    },
    {
        letter: 'I',
        frequency: 12,
        isVowel: true
    },
    {
        letter: 'J',
        frequency: 2
    },
    {
        letter: 'K',
        frequency: 2
    },
    {
        letter: 'L',
        frequency: 5
    },
    {
        letter: 'M',
        frequency: 3
    },
    {
        letter: 'N',
        frequency: 8
    },
    {
        letter: 'O',
        frequency: 11,
        isVowel: true
    },
    {
        letter: 'P',
        frequency: 3
    },
    {
        letter: 'R',
        frequency: 9
    },
    {
        letter: 'S',
        frequency: 6
    },
    {
        letter: 'T',
        frequency: 9
    },
    {
        letter: 'U',
        frequency: 6,
        isVowel: true
    },
    {
        letter: 'V',
        frequency: 3
    },
    {
        letter: 'W',
        frequency: 3
    },
    {
        letter: 'X',
        frequency: 2
    },
    {
        letter: 'Y',
        frequency: 3
    },
    {
        letter: 'Z',
        frequency: 2
    }
];
