import { Injectable } from '@angular/core';

import { dictionary } from 'src/assets/dictionary';

@Injectable({
  providedIn: 'root'
})
export class DictionaryService {
    isValidWord(word: string): boolean {
        const wordToCheck = word.trim().toLowerCase();
        return dictionary.includes(wordToCheck);
    }
}
