import { gridDimensions } from '../constants/dimensions';
import { invalidSquare, validSquare } from '../constants/emojis';

export function gridToEmojis(grid: string[][]): string {
    const rows = groupSpaces(grid);
    const usedSpaces = reduceGrid(rows);
    return gridRowsToString(usedSpaces);
}

/**
 * Groups spaces into row arrays based on grid dimensions
 * @param grid The raw array of grid spaces to group into rows
 */
function groupSpaces(grid: string[][]): string[][] {
    const result = [];
    let currentRow: string[] = [];

    for (let i = 0; i < grid.length; i++) {
        const spaceValue = grid[i].length ? grid[i][0] : '';
        currentRow.push(spaceValue);

        // If end of a row, add to return value and start a new row
        if (i % gridDimensions.x === gridDimensions.x - 1) {
            result.push(currentRow);
            currentRow = [];
        }
    }

    return result;
}

/**
 * Removes unused columns and rows in a grid
 * @param grid The ordered array of grid rows
 */
function reduceGrid(grid: string[][]): string[][] {
    let result = [ ...grid ];

    result = clearRows(result);
    result = clearRows(result, false);

    result = clearColumns(result);
    result = clearColumns(result, false);

    return result;
}

/**
 * Removes unused rows
 * @param grid The ordered array of grid rows
 * @param fromStart Flag to remove from the start or end of the array
 */
function clearRows(grid: string[][], fromStart = true): string [][] {
    let clearRow = true;

    while (clearRow) {
        if (!grid.length || !grid[0].length) {
            break;
        }

        const index = fromStart ? 0 : grid.length - 1;
        // If every space in the row is empty, remove the row
        if (grid[index].every(space => !space.length)) {
            grid.splice(index, 1);
        } else {
            clearRow = false;
        }
    }

    return grid;
}

/**
 * Removes unused columns
 * @param grid The ordered array of grid rows
 * @param fromStart Flag to remove from the start or end of the array
 */
function clearColumns(grid: string[][], fromStart = true): string[][] {
    let clearColumn = true;

    while (clearColumn) {
        if (!grid.length || !grid[0].length) {
            break;
        }

        const index = fromStart ? 0 : grid[0].length - 1;
        // If every space in the column is empty, remove the row
        if (grid.every(row => !(row[index] || []).length)) {
            grid.forEach(row => row.splice(index, 1));
        } else {
            clearColumn = false;
        }
    }

    return grid;
}

/**
 * Translates an array of ordered grid rows into an emoji string
 * @param grid The ordered array of grid rows
 */
function gridRowsToString(grid: string[][]): string {
    const emojiGrid = grid.map(row => row.map(spaceValue => spaceValue.length ? validSquare : invalidSquare));
    const emojiStrings = emojiGrid.map(row => row.join(''));
    return emojiStrings.join('\r\n');
}
