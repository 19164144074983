// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyAQaXw3_RNAbLiQbsySxvLL690oHpDk-8Y',
    authDomain: 'jevans-griddle.firebaseapp.com',
    databaseURL: 'https://jevans-griddle-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'jevans-griddle',
    storageBucket: 'jevans-griddle.appspot.com',
    messagingSenderId: '539379678292',
    appId: '1:539379678292:web:74f414ba975ce7db9a01c1',
    measurementId: 'G-VFCFFC670P'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
