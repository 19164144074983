import { DictionaryService } from '../services/dictionary.service';
import { Coordinates } from './coordinates';
import { GridResult } from './grid-result';
import { GridSpace } from './grid-space';

export class Grid {
    private spaces: GridSpace[];

    constructor(fields: string[][], private gridSize: Coordinates, private dictionaryService: DictionaryService) {
        this.spaces = this.generateSpaces(fields);
    }

    private generateSpaces(fields: string[][]): GridSpace[] {
        return fields.map<GridSpace>((field, index) => {
            const x = (index % this.gridSize.x) + 1;
            const y = this.gridSize.y - (Math.floor(index / this.gridSize.x));
            const coordinates = new Coordinates(x, y);
            const letter = field.length ? field[0] : undefined;

            return { index, coordinates, letter } as GridSpace;
        });
    }

    public validate(): GridResult {
        const horizontalWordGroups = this.getHorizontalWords();
        const verticalWordGroups = this.getVerticalWords();

        const result = new GridResult();

        const looseLetters = horizontalWordGroups.filter(hWord => {
            if (hWord.length > 1) {
                return false;
            }
            return verticalWordGroups.some(vWord => vWord.includes(hWord[0]) && vWord.length === 1);
        });
        if (looseLetters.length) {
            result.separatedWords.push(...looseLetters);
        }

        const allWordGroups = [ ...horizontalWordGroups, ...verticalWordGroups ].filter(word => word.length > 1);
        allWordGroups.forEach(wordGroup => {
            if (allWordGroups.length > 1 &&
                !allWordGroups.some(otherWord => otherWord !== wordGroup && otherWord.some(letter => wordGroup.includes(letter)))
            ) {
                result.separatedWords.push(wordGroup);
            }

            const word = this.getWord(wordGroup);
            if (this.dictionaryService.isValidWord(word)) {
                result.validWords.push(wordGroup);
            } else {
                result.invalidWords.push(wordGroup);
            }
        });

        return result;
    }

    private getHorizontalWords(): GridSpace[][] {
        const checkedSpaces: GridSpace[] = [];
        const result: GridSpace[][] = [];
        const nextTileDirection = new Coordinates(1, 0);

        for (let y = this.gridSize.y; y > 0; y--) {
            for (let x = 1; x <= this.gridSize.x; x++) {
                const coordinates = new Coordinates(x, y);

                const word = this.getWordAtCoordinates(coordinates, nextTileDirection, checkedSpaces);
                if (word !== null) {
                    result.push(word);
                }
            }
        }

        return result;
    }

    private getVerticalWords(): GridSpace[][] {
        const checkedSpaces: GridSpace[] = [];
        const result: GridSpace[][] = [];
        const nextTileDirection = new Coordinates(0, -1);

        for (let x = 1; x <= this.gridSize.x; x++) {
            for (let y = this.gridSize.y; y > 0; y--) {
                const coordinates = new Coordinates(x, y);

                const word = this.getWordAtCoordinates(coordinates, nextTileDirection, checkedSpaces);
                if (word !== null) {
                    result.push(word);
                }
            }
        }

        return result;
    }

    private getWordAtCoordinates(coordinates: Coordinates, nextTileDirection: Coordinates, checkedSpaces: GridSpace[]): GridSpace[] | null {
        let space = this.getSpaceAtCoordinates(coordinates);
        if (space === null || checkedSpaces.includes(space)) {
            return null;
        }

        if (!space.letter) {
            checkedSpaces.push(space);
            return null;
        }

        const result: GridSpace[] = [];
        let nextSpaceCoordinates = coordinates.duplicate();

        do {
            result.push(space);
            checkedSpaces.push(space);

            nextSpaceCoordinates = nextSpaceCoordinates.add(nextTileDirection);
            space = this.getSpaceAtCoordinates(nextSpaceCoordinates);
        } while (space !== null && !!space.letter);

        return result;
}

    private getSpaceAtCoordinates(coordinates: Coordinates): GridSpace | null {
        return this.spaces.find(s => s.coordinates.equals(coordinates)) ?? null;
    }

    private getWord(spaces: GridSpace[]): string {
        return spaces.map(space => space.letter).join('');
    }
}
