import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';

import { Store } from '@ngxs/store';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { gridDimensions } from '../constants/dimensions';
import { timeToString } from '../functions/time-to-string';
import { Coordinates } from '../models/coordinates';
import { GameResult } from '../models/game-result';
import { Grid } from '../models/grid';
import { GridResult } from '../models/grid-result';
import { GridSpace } from '../models/grid-space';
import { SpaceValidity } from '../models/space-validity.enum';
import { DictionaryService } from '../services/dictionary.service';
import { SetWindowFocus } from '../store/app.actions';
import { AppState } from '../store/app.state';

import { UUID } from 'angular2-uuid';

@Component({
    selector: 'app-game',
    templateUrl: './game.component.html',
    styleUrls: ['./game.component.scss']
})
@UntilDestroy()
export class GameComponent implements OnInit, OnChanges {
    @Input() letters: string[] = [];

    @Output() gameCompleted: EventEmitter<GameResult> = new EventEmitter();
    @Output() gameReset: EventEmitter<any> = new EventEmitter();
    @Output() shareGameClicked: EventEmitter<GameResult> = new EventEmitter();

    public gridSpaces: string[][] = [];
    public validSpaces: Record<number, SpaceValidity> = {};
    public bank1: string[] = [];
    public bank2: string[] = [];
    public invalidGrid = false;
    public gameStarted = false;
    public gameComplete = false;

    public gameId = UUID.UUID();

    public clock = 0;
    public clockTick: NodeJS.Timeout | null = null;
    public paused = false;

    public Math = Math;
    public SpaceValidity = SpaceValidity;
    public timeToString = timeToString;

    constructor(
        private store: Store,
        private analytics: AngularFireAnalytics,
        private dictionaryService: DictionaryService
    ) {}

    ngOnInit(): void {
        this.store.select(AppState.pause)
            .pipe(untilDestroyed(this))
            .subscribe((pause: boolean) => this.pauseGame(pause));
    }

    ngOnChanges(changes: SimpleChanges): void {
        // tslint:disable-next-line:no-string-literal
        const letterChanges = changes['letters'];
        if (!!letterChanges) {
            this.onLettersChanged();
        }
    }

    @HostListener('window:focus', ['$event'])
    onFocus(_: any): void {
        this.store.dispatch(new SetWindowFocus(true));
    }

    @HostListener('window:blur', ['$event'])
    onBlur(_: any): void {
        this.store.dispatch(new SetWindowFocus(false));
    }

    private pauseGame(pause: boolean): void {
        this.paused = pause;
    }

    public setCompletedGrid(result: GameResult): void {
        this.gameComplete = true;
        this.gameStarted = true;
        this.invalidGrid = false;

        this.clock = result.time;

        this.bank1 = [];
        this.bank2 = [];
        this.gridSpaces = result.grid;
        this.validSpaces = {};

        this.setGridValidity();
    }

    public resetGame(): void {
        this.resetValidity();

        this.gameComplete = false;
        this.gameStarted = false;

        this.gameId = UUID.UUID();

        this.clock = 0;

        this.gameReset.emit();
    }

    private onLettersChanged(): void {
        this.returnLetters();
    }

    public startClock(): void {
        this.gameStarted = true;

        this.analytics.logEvent('game_started', { gameId: this.gameId });

        if (!!this.clockTick) {
            return;
        }

        this.clockTick = setInterval(() => {
            if (this.paused) {
                return;
            }

            this.clock++;
        }, 1000);
    }

    private stopClock(): void {
        if (!!this.clockTick) {
            clearInterval(this.clockTick);
            this.clockTick = null;
        }
    }

    public drop(event: CdkDragDrop<string[]>, isBank = false): void {
        if (event.previousContainer === event.container) {
            if (isBank) {
                moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
            }
        } else {
            if (!isBank && event.container.data.length > 0) {
                transferArrayItem(
                    event.previousContainer.data,
                    event.container.data,
                    event.previousIndex,
                    0,
                );

                transferArrayItem(
                    event.container.data,
                    event.previousContainer.data,
                    1,
                    event.previousIndex
                );
            } else {
                transferArrayItem(
                    event.previousContainer.data,
                    event.container.data,
                    event.previousIndex,
                    event.currentIndex,
                );
            }

            if (isBank) {
                while (this.bank1.length > 5) {
                    transferArrayItem(
                        this.bank1,
                        this.bank2,
                        this.bank1.length - 1,
                        0,
                    );
                }
                while (this.bank2.length > 5) {
                    transferArrayItem(
                        this.bank2,
                        this.bank1,
                        0,
                        this.bank1.length
                    );
                }
            }
        }
    }

    public validate(): void {
        if (!this.gridSpaces.some(space => !!space.length)) {
            return;
        }

        const result = this.setGridValidity();

        if (!this.bank1.length && !this.bank2.length && result.isValid) {
            // Game complete
            this.gameComplete = true;

            this.stopClock();

            this.analytics.logEvent('game_complete', { gameId: this.gameId });
            this.gameCompleted.emit(new GameResult(this.clock, this.letters, this.gridSpaces));
        } else {
            // Invalid grid
            if (!result.isValid) {
                this.invalidGrid = true;
                this.clock += 10;
            }

            setTimeout(() => this.resetValidity(), 1000);
        }
    }

    private setGridValidity(): GridResult {
        const grid = new Grid(this.gridSpaces, gridDimensions, this.dictionaryService);
        const result = grid.validate();

        this.validSpaces = {};

        this.setValid(result.validWords, SpaceValidity.Valid);
        this.setValid(result.invalidWords, SpaceValidity.Invalid);
        this.setValid(result.separatedWords, SpaceValidity.Invalid);

        return result;
    }

    private setValid(words: GridSpace[][], validity: SpaceValidity): void {
        const spaces = words.reduce((acc, curr) => {
            acc.push(...curr);
            return acc;
        }, []);

        spaces.forEach(space => {
            const index = this.getIndexOf(space);
            this.validSpaces[index] = validity;
        });
    }

    private getIndexOf(space: GridSpace): number {
        return (space.coordinates.x - 1) + ((gridDimensions.y - space.coordinates.y) * gridDimensions.x);
    }

    public resetValidity(index?: number): void {
        if (!!index) {
            delete this.validSpaces[index];
        } else {
            this.invalidGrid = false;
            this.validSpaces = {};
        }
    }

    public returnLetters(): void {
        for (let i = 0; i < gridDimensions.x * gridDimensions.y; i++) {
            this.gridSpaces[i] = [];
        }

        this.bank1 = [...this.letters].splice(0, 5);
        this.bank2 = [...this.letters].splice(5, 5);
    }

    public share(): void {
        this.shareGameClicked.emit(new GameResult(this.clock, this.letters, this.gridSpaces));
    }
}
