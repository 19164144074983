import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFireDatabaseModule } from '@angular/fire/database';

import { environment } from 'src/environments/environment';

@NgModule({
    imports: [
        AngularFireModule.initializeApp(environment.firebase, 'jevans-griddle'),
        AngularFireAuthModule,
        AngularFireAnalyticsModule,
        AngularFireDatabaseModule,
    ],
    exports: [
        AngularFireModule,
        AngularFireAuthModule,
        AngularFireAnalyticsModule,
        AngularFireDatabaseModule,
    ],
    declarations: [],
})
export class FirebaseModule { }
